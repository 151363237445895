<template>
	<w-layout>
		<w-flex>
			<w-text-input
				v-model="application.app_store_id"
				:disabled="!canEdit"
				type="number"
				auto-focus
				color="primary"
				:label="appStoreIdFieldName"
			></w-text-input>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppAppStoreID',
	props: {
		value: {
			required: true,
			type: Object
		},
		canEdit: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		appStoreIdFieldName: function () {
			return this.$t('mobileapp.fields.app_store_id')
		}
	}
}
</script>
